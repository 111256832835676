const cf = {
  '*Please provide your phone number, to obtain the shipping rates':
    '*Please provide your phone number, to obtain the shipping rates',
  'To overwrite system generating account format you must upload a void cheque or direct deposit form':
    'To overwrite system generating account format you must upload a void cheque or direct deposit form',
  'You have selected an account with the option to upload a void cheque':
    'You have selected an account with the option to upload a void check',
  'Bank Province': 'Bank State',
  'Company Province': 'Company State',
  'Reverse Numbering': 'Reverse Numbering',
  'Bank Postal Code': 'Bank ZIP Code',
  'Company Postal Code': 'Company ZIP Code',
  'VOID CHEQUE': 'VOID CHEQUE',
  'Select Security Features': 'Select Security Features',
  'SOFTWARE TYPE': 'SOFTWARE TYPE',
  Apply: 'Apply',
  Coupon: 'Coupon',
  'SELECT CHEQUE TYPE': 'SELECT CHEQUE TYPE',
  'LOG OUT': 'LOG OUT',
  'Contact Phone': 'Contact Phone',
  'Email to': 'Email to',
  'Email to send your invoice': 'Email to send your invoice',
  'Follow us on': 'Follow us on',
  'Currency type': 'Currency type',
  'Login/Register': 'Login/Register',
  Username: 'Username',
  Password: 'Password',
  'Please type your username!': 'Please type your username!',
  'Please type your password!': 'Please type your password!',
  'Sign in': 'Sign in',
  'Sign up': 'Sign up',
  'Forgot your password?': 'Forgot your password?',
  "Don't have an account?": "Don't have an account?",
  'Cheques Fulfillment is Payments Canada Self Accredited': 'Cheques Fulfillment is Payments Canada Self Accredited',
  'Identity Assured up to $1.750,000': 'Identity Assured up to $1.750,000',
  'Copyright © 2021 qplus. All rights reserved': 'Copyright © 2021 qplus. All rights reserved',
  'Welcome to qplus.app': 'Welcome to qplus.app',
  'Try our products risk-free. All of our products come with a 100% satisfaction guarantee.':
    'Try our products risk-free. All of our products come with a 100% satisfaction guarantee.',
  'ORDER CHEQUES': 'ORDER CHEQUES',
  'Buy now': 'Buy now',
  'Are you a returning customer?': 'Are you a returning customer?',
  'Re-order cheques now': 'Re-order cheques now',
  'Business cheques with an extra!': 'Business cheques with an extra!',
  'qplus.app at your service!': 'qplus.app at your service!',
  'Welcome to qplus.app, the cost-effective, friendly alternative to purchasing pre-printed computer cheques from financial institutions. We carry all of the popular styles of computer and business cheques that big banks do, and we use the same suppliers, allowing us to rival the quality of the products they sell. Our #1 priority is ensuring that your business and personal cheques are printed correctly and shipped quickly.':
    'Welcome to qplus.app, the cost-effective, friendly alternative to purchasing pre-printed computer cheques from financial institutions. We carry all of the popular styles of computer and business cheques that big banks do, and we use the same suppliers, allowing us to rival the quality of the products they sell. Our #1 priority is ensuring that your business and personal cheques are printed correctly and shipped quickly.',
  'The pre-printed cheques offered by qplus.app are compliant with all of the guidelines established by the Canadian Payment Association, and since we use the same suppliers as banks, our cheques are of the same quality that companies receive when they place their initial order or re-orders with their financial institutions. We offer lighting fast delivery; typically, we can ship purchases placed before 1:30 p.m. on the same day as the order.':
    'The pre-printed cheques offered by qplus.app are compliant with all of the guidelines established by the Canadian Payment Association, and since we use the same suppliers as banks, our cheques are of the same quality that companies receive when they place their initial order or re-orders with their financial institutions. We offer lighting fast delivery; typically, we can ship purchases placed before 1:30 p.m. on the same day as the order.',
  'Every order is processed with rush service standards, shipping and handling are free for all orders within Ontario and Quebec. Every order received at qplus.app is important, regardless of its size. Customer satisfaction is extremely important to us, and as such, we are available to assist our customers with selecting pre-printed computer cheques, with questions regarding how to order on our website, and with inquiries about orders that have already been placed, filled, or shipped. We can be reached by phone at 450.323.6244 or use our contact form.':
    'Every order is processed with rush service standards, shipping and handling are free for all orders within Ontario and Quebec. Every order received at qplus.app is important, regardless of its size. Customer satisfaction is extremely important to us, and as such, we are available to assist our customers with selecting pre-printed computer cheques, with questions regarding how to order on our website, and with inquiries about orders that have already been placed, filled, or shipped. We can be reached by phone at 450.323.6244 or use our contact form.',
  'Phone number:': 'Phone number:',
  'Contact email:': 'Contact email:',
  'North America Toll Free': 'North America Toll Free',
  'For service in French': 'For service in French',
  'Shopping cart': 'Shopping cart',
  Login: 'Login',
  'Please type your email!': 'Please type your email!',
  'Send link': 'Send link',
  'START YOUR ORDER': 'START YOUR ORDER',
  Price: 'Price',
  Cheques: 'Cheques',
  Personalization: 'Personalization',
  'Tax (GST/QST)': 'Tax (GST/QST)',
  Subtotal: 'Subtotal',
  'Pay now': 'Pay now',
  STEP: 'STEP',
  'QUANTITY & COLOR': 'QUANTITY & COLOR',
  PERSONALIZATION: 'PERSONALIZATION',
  'BANK INFORMATION': 'BANK INFORMATION',
  'ADDITIONAL OPTIONS': 'ADDITIONAL OPTIONS',
  'Please type your email address to start your order': 'Please type your email address to start your order:',
  'Your email': 'Your email',
  'Select quantity': 'Select quantity:',
  'Select color': 'Select color:',
  Back: 'Back',
  Finish: 'Finish',
  Next: 'Next',
  'Choose the personalization font': 'Choose the personalization font',
  'Name on account': 'Name on account',
  City: 'City',
  State: 'State',
  'ZIP code': 'ZIP code',
  'Bank not selected': 'Bank not selected',
  'Select an available bank from the list': 'Select an available bank from the list',
  'Account ending in': 'Account ending in',
  Nickname: 'Nickname',
  'Choose from your existing accounts': 'Choose from your existing accounts',
  OR: 'OR',
  'Add a bank account to your profile': 'Add a bank account to your profile',
  'Type your 5-digit transit (branch) number to retrieve your bank details':
    'Type your 5-digit transit (branch) number to retrieve your bank details',
  'Search here': 'Search here',
  'You chose the following branch': 'You chose the following branch',
  'Please type your 7 or 12 digit account number': 'Please type your 7 or 12 digit account number',
  'Please re-type your account number': 'Please re-type your account number',
  'The two numeric accounts you typed do not match!': 'The two numeric accounts you typed do not match!',
  'Please type starting cheque number': 'Please type starting cheque number',
  'US dollar account?': 'US dollar account?',
  English: 'English',
  French: 'French',
  'Choose language': 'Choose language',
  'Please type your full name to start your order': 'Please type your full name to start your order',
  'Full name': 'Full name',
  'Personal cheques': 'Personal cheques',
  'Are you sure to delete this product?': 'Are you sure to delete this product?',
  Product: 'Product',
  Quantity: 'Quantity',
  'Make an order': 'Make an order',
  'Are you sure to send the order?': 'Are you sure to send the order?',
  Size: 'Size',
  Color: 'Color',
  'Create an account': 'Create an account',
  'Please, create your account': 'Please, create your account',
  'Email address': 'Email address',
  'Please, type a valid password. Minimum 6 characters long.':
    'Please, type a valid password. Minimum 6 characters long.',
  'Please, Type your full account number': 'Please, Type your full account number',
  'Shipping address': 'Shipping address',
  'Please, fill in your shipping address': 'Please, fill in your shipping address',
  'Contact name': 'Contact name',
  'Company name': 'Company name',
  Optional: 'Optional',
  'Phone number': 'Phone number',
  'Country/Region': 'Country/Region',
  'Please select': 'Please select',
  'House number and street name': 'House number and street name',
  'Apartment, suite, unit, etc.': 'Apartment, suite, unit, etc.',
  'Type your city': 'Type your city',
  'Type your ZIP code': 'Type your ZIP code',
  'Payment method': 'Payment method',
  'Please, select your payment method': 'Please, select your payment method',
  'Credit/debit card': 'Credit/debit card',
  'Card number': 'Card number',
  'Expiration date': 'Expiration date',
  YY: 'YY',
  'What is this?': 'What is this?',
  'Pay with PayPal': 'Pay with PayPal',
  'Cash on delivery': 'Cash on delivery',
  'Order review': 'Order review',
  'Review and confirm order': 'Review and confirm order',
  'Shipping information': 'Shipping information',
  '+ Add new address': '+ Add new address',
  '+ Add new card': '+ Add new card',
  Discount: 'Discount',
  'Shipping cost': 'Shipping cost',
  'Successful payment': 'Successful payment',
  'Thank you! We have received your payment': 'Thank you! We have received your payment',
  'Unselected shipping': 'Unselected shipping',
  'Select an available shipping from the list': 'Select an available shipping from the list',
  'Order summary': 'Order summary',
  'Select shipping': 'Select shipping',
  'day(s)': 'day(s)',
  'Keep shopping': 'keep shopping',
  'Add to cart': 'Add to cart',
  'Update product': 'Update product',
  'Add product': 'Add product',
  'Add manual order': 'Add manual order',
  'Create a new order': 'Create a new order',
  'This field is required': 'This field is required',
  'Business cheques': 'Business cheques',
  'Computer cheques': 'Computer cheques',
  'Manual cheques': 'Manual cheques',
  'Blank cheques': 'Blank cheques',
  'Handwritten cheques': 'Handwritten cheques',
  '2 copies (Carbon)': '2 copies (Carbon)',
  'Starter kits': 'Starter kits',
  Accessories: 'Accessories',
  'Deposit slip': 'Deposit slip',
  'Fraud prevention pen': 'Fraud prevention pen',
  'Customized deposit books': 'Customized deposit books',
  'Customized deposit books for RBC': 'Customized deposit books for RBC',
  '2 books - $35.00': '2 books - $35.00',
  '4 books - $49.00': '4 books - $49.00',
  Add: 'Add',
  'Account Edge computer deposit slips': 'Account Edge computer deposit slips',
  'Saved information': 'Saved information',
  'The following fields have errors': 'The following fields have errors',
  'Commercial group': 'Commercial group',
  'Search by name': 'Search by name',
  'Add new commercial group': 'Add new commercial group',
  'Add commercial group': 'Add commercial group',
  Name: 'Name',
  Role: 'Role',
  'Commercial groups': 'Commercial groups',
  User: 'User',
  'Add user to commercial group': 'Add user to commercial group',
  'User to commercial group': 'User to commercial group',
  'View all': 'View all',
  'Add new': 'Add new',
  'Profile picture': 'Profile picture',
  'Type your name': 'Type your name',
  'example@gmail.com': 'example@gmail.com',
  Country: 'Country',
  Bangladesh: 'Bangladesh',
  India: 'India',
  Dhaka: 'Dhaka',
  'Type the company name': 'Type the company name',
  'Type the position': 'Type the position',
  'Join date': 'Join date',
  Status: 'Status',
  Active: 'Active',
  Inactive: 'Inactive',
  Blocked: 'Blocked',
  'Loading...': 'Loading...',
  Submit: 'Submit',
  'Update your registration': 'Update your registration',
  'Are you sure to delete this?': 'Are you sure to delete this?',
  'Data list': 'Data list',
  'Please enter the minimum content': 'Please enter the minimum content',
  'Post something': 'Post something',
  'Type something...': 'Type something...',
  'Photo/Video': 'Photo/Video',
  'Post it!': 'Post it!',
  Like: 'Like',
  Reply: 'Reply',
  'file uploaded successfully!': 'file uploaded successfully!',
  'file upload failed.': 'file upload failed.',
  Delete: 'Delete',
  Share: 'Share',
  'Type a comment...': 'Type a comment...',
  'Please type your comment!': 'Please type your comment!',
  'Send you a message': 'Send you a message',
  '5 hours ago': '5 hours ago',
  Hide: 'Hide',
  'upload website template for sale': 'upload website template for sale',
  'has registered': 'has registered',
  Print: 'Print invoice',
  'Samsung Galaxy S8 256 GB': 'Samsung Galaxy S8 256 GB',
  $280: '$280',
  '$38,536': '$38,536',
  'Half sleeve shirt': 'Half sleeve shirt',
  $25: '$25',
  $32: '$32',
  $950: '$950',
  $985: '$985',
  'Product name': '',
  Sold: 'Sold',
  Revenue: 'Revenue',
  'My Products': 'My Products',
  Friends: 'Friends',
  Follow: 'Follow',
  Following: 'Following',
  'Load more friends': 'Load more friends',
  Videos: 'Videos',
  'User bio': 'User bio',
  'Contact info': 'Contact info',
  'www.example.com': 'www.example.com',
  Skills: 'Skills',
  'UI/UX': 'UI/UX',
  Branding: 'Branding',
  'Product design': 'Product design',
  'Website design': 'Website design',
  App: 'App',
  'Social profiles': 'Social profiles',
  '$72,572': '$72,572',
  'Total revenue': 'Total revenue',
  Orders: 'Orders',
  Products: 'Products',
  Overview: 'Overview',
  Timeline: 'Timeline',
  Activity: 'Activity',
  'Change cover': 'Change cover',
  Success: 'Success',
  'Customer updated successfully!': 'Customer updated successfully!',
  'Customer added successfully!': 'Customer added successfully!',
  'First name': 'First name',
  'First name is required': 'First name is required',
  'Last name': 'Last name',
  'Last name is required': 'Last name is required',
  Gender: 'Gender',
  'Gender is required': 'Gender is required',
  'Please select a gender': 'Please select a gender',
  Male: 'Male',
  Female: 'Female',
  Other: 'Other',
  'Please type a valid email!': 'Please type a valid email!',
  Language: 'Language',
  'Language is required': 'Language is required',
  'Please select a language': 'Please select a language',
  Reseller: 'Reseller',
  'Reseller is required': 'Reseller is required',
  'Please select a reseller': 'Please select a reseller',
  'No reseller found': 'No reseller found',
  'Update customer': 'Update customer',
  'Create customer': 'Create customer',
  Customers: 'Customers',
  '+ Add new': '+ Add new',
  'Customer deleted successfully!': 'Customer deleted successfully!',
  Error: 'Error',
  'Oops, we are having trouble loading clients': 'Oops, we are having trouble loading clients',
  of: 'of',
  items: 'items',
  'No data found. Please search for a customer.': 'No data found. Please search for a customer.',
  Yes: 'Yes',
  No: 'No',
  'Do you want to edit this product?': 'Do you want to edit this product?',
  'United States': 'United States',
  'Something went wrong processing your order': 'Something went wrong processing your order',
  'Please contact us if you need help': 'Please contact us if you need help',
  'Send to': 'Send to',
  'Shipping location': 'Shipping location',
  'Modify shipping addresses': 'Modify shipping addresses',
  'Please, click the link we send you with your login credentials':
    'Please, click the link we send you with your login credentials',
  'Please, provide your email and we will send you a login link':
    'Please, provide your email and we will send you a login link',
  'It is not a valid email!': 'It is not a valid email!',
  Blue: 'Blue',
  None: 'None',
  Help: 'Help',
  Welcome: 'Welcome',
  'Do you want to': 'Do you want to',
  'sign out?': 'sign out?',
  'Your last 3 orders': 'Your last 3 orders',
  Order: 'Order',
  'Qty.': 'Qty.',
  'Do you want to order this again?': 'Do you want to order this again?',
  'Re-order now': 'Re-order now',
  'My addresses': 'My addresses',
  'Delivery address book': 'Delivery address book',
  'Please type your first name': 'Please type your first name',
  Address: 'Address',
  'Please type your address': 'Please type your address',
  Street: 'Street',
  'Please type your street': 'Please type your street',
  'Address (line 2)': 'Address (line 2)',
  'Please type your country': 'Please type your country',
  'Please type your state': 'Please type your state',
  'Country code': 'Country code',
  'State code': 'State code',
  'Please type your city': 'Please type your city',
  'Please type your ZIP code': 'Please type your ZIP code',
  Cancel: 'Cancel',
  Update: 'Update',
  Save: 'Save',
  'and continue': 'and continue',
  addresses: 'addresses',
  Select: 'Select',
  'Send code': 'Send code',
  'Please provide your phone number or e-mail and we will send you a code to login':
    'Please provide your phone number or e-mail and we will send you a code to login',
  'Phone number or Email': 'Phone number or Email',
  'We are ready to start your order': 'We are ready to start your order',
  'please enter the code we have sent to': 'please enter the code we have sent to',
  'Did not you get the code?': 'Did not you get the code?',
  'New address': 'New address',
  'When placing an order again, the prices will be updated and the availability of the products will be verified.':
    'When placing an order again, the prices will be updated and the availability of the products will be verified.',
  'Shipping discount': 'Shipping discount',
  'Order now': 'Order now',
  More: 'More',
  'resend code': 'resend code',
  'See my orders': 'See my orders',
  'Re-order': 'Re-order',
  'Re-order and edit': 'Re-order and edit',
  'Do you want to re-order and edit this?': 'Do you want to re-order and edit this?',
  'Ship to': 'Ship to',
  'Change billing address': 'Change billing address',
  'Billing address': 'Billing address',
  'Change shipping address': 'Change shipping address',
  'Shipping Methods': 'Shipping Methods',
  'Firstname on card': 'Firstname on card',
  'Lastname on card': 'Lastname on card',
  'Pay with credit': 'Pay with credit',
  'Additional Options': 'Additional Options',
  'Transit Days': 'Transit Days',
  'Total Charge': 'Total Charge',
  'Postal code': 'ZIP code',
  'Show cheque': 'Show cheque',
  'My books': 'My books',
  'Address book': 'Address book',
  'Bank book': 'Bank book',
  'Bank account': 'Bank account',
  Customer: 'Customer',
  'You have been requested to pay': 'You have been requested to pay',
  "What's in this order": "What's in this order",
  'WE REQUIRE YOUR APPROVAL': 'WE REQUIRE YOUR APPROVAL',
  'By approving you are agreeing that all information is correct.':
    'By approving you are agreeing that all information is correct.',
  'For these reasons, any refunds on customized products request after the approval request cannot be honored':
    'For these reasons, any refunds on customized products request after the approval request cannot be honored',
  'PLEASE CAREFULLY REVIEW YOUR DETAILS BEFORE SUBMITTING YOUR ORDER':
    'PLEASE CAREFULLY REVIEW YOUR DETAILS BEFORE SUBMITTING YOUR ORDER',
  'Your order has been sent': 'Your order has been sent',
  'Click on the logo in the navigation bar to continue shopping':
    'Click on the logo in the navigation bar to continue shopping',

  'Voided Cheque Sample': 'Voided Cheque Sample',
  'Please select a option': 'Please select a option',
  Upload: 'Upload',
  'I Do Not Have a Void Cheque': 'I Do Not Have a Void Cheque',
  'type in your postal code to populate your address': 'type in your postal code to populate your address',
  'Addresses used on the check cannot be deleted or edited': 'Addresses used on the check cannot be deleted or edited',
  proceed: 'Continue editing',
  i_approve_it: 'I APPROVE IT',
  'PLEASE CAREFULLY REVIEW YOUR DATA IS CORRECT': 'PLEASE CAREFULLY REVIEW YOUR DATA IS CORRECT',
  confirm_changes: 'CONFIRM CHANGES',
  'Pay by credit card': 'Pay by Credit Card',
  'Pay by direct debit': 'Pay by Direct Debit',
  'Unselected bank account for debit': 'Unselected bank account for debit',
  'Select an available bank account from your list': 'Select an available bank account from your list',
  'Tax info missing': 'Tax info missing',
  'Your address is not complete, please update it before sending your order': 'Your address is not complete, please update it before sending your order',
  'Choose signature lines': 'Choose signature lines',
  '1 Signature Required': '1 Signature Required',
  '2 Signature Lines': '2 Signature Lines',
  '3 Signature Lines': '3 Signature Lines',
  'Company Phone': 'Company Phone',
  "Custom Text Above Signature Line (optional)": "Custom Text Above Signature Line (optional)",
  "Custom Text Above Signature Line 2 (optional)": "Custom Text Above Signature Line 2 (optional)",
  'type in or update your street name to populate your address': 'type in or update your street name to populate your address',
  'Please verify your address is correct before saving the information': 'Please verify your address is correct before saving the information',
  'terms_conditions': 'I agree to the terms and conditions',
  'Please retype your address in the searchbar': 'Please retype your address in the searchbar',
  'Cheque type': 'Cheque type',
  'We apologize, the selected color is out of stock, it will be available again on': 'We apologize, the selected color is out of stock, it will be available again on',
  'Color selection': 'Color selection',
  'Keep letter case as entered': 'Keep letter case as entered',

};

const en = {
  translation: {
    ...cf,
  },
};

export default en;
